<template>
  <base-page
      :title="$t('accountPermissionManage.title')"
      :formData="formData"
      :config="config"
      @functionMethod="functionMethod"
      @search="search"
  >
    <table-list
        ref="tableList"
        :url="'/' + $util.getLocalStorage('apiprefix') + $api.findAccountPermissionList"
        :defaultParams="params"
        :columns="columns"
        v-model="tableRow"
    >
    </table-list>
    <detail v-if="showDetail" :tableRow="tableRow" @closePage="closePage" />
  </base-page>
</template>

<script>
import Detail from './detail'

export default {
  components: {
    Detail,
  },
  data() {
    const lang = this.$util.getLocalStorage('lang')
    let _that = this
    return {
      importTemplate: `/template/${lang}/customer.xlsx`,
      // 搜索
      formData: {},
      config: [
        {
          label: this.$t('base.searchStr'),
          prop: 'searchStr',
          span: 6,
          tagProps: {
            placeholder: this.$t('accountPermissionManage.nameOrAccount'),
          },
        },
        // lang:所属部门
        {
          tag: 'el-cascader',
          label: this.$t('accountPermissionManage.accountGroup'),
          prop: 'userAccountGroupId',
          span: 8,
          tagProps: {
            options: [],
            props: {
              label: 'name',
              value: 'id',
            },
          },
        },
      ],
      // 表格页
      tableRow: null,
      params: {
        needFullAddress: 'YES',
      },
      columns: [
        // lang: 姓名
        {
          label: this.$t('accountPermissionManage.name'),
          prop: 'name',
        },
        // lang:部门
        {
          label: this.$t('accountPermissionManage.department'),
          prop: 'groupName',
        },
        // lang:账号
        {
          label: this.$t('accountPermissionManage.account'),
          prop: 'accountId',
        },
        // lang:产品密钥
        {
          label: this.$t('accountPermissionManage.productKey'),
          prop: 'secretKeyDistributionList',
          callback(row) {
            if(row.secretKeyDistributionList.length !== 0) {
              let productKey = row.secretKeyDistributionList.filter(item => item.type === 'PRODUCT_KEY')
              if(productKey.length !== 0) {
                return _that.$t('accountPermissionManage.end') + _that.$util.format(productKey[0].expirationDate)
              }
            }
            return _that.$t('accountPermissionManage.notHave')
          },
        },
        // lang:热成像功能
        {
          label: this.$t('accountPermissionManage.thermalImagingFunction'),
          prop: 'secretKeyDistributionList',
          callback(row) {
            if(row.secretKeyDistributionList.length !== 0) {
              let productKey = row.secretKeyDistributionList.filter(item => item.type === 'THERMAL_IMAGERY_FUNCTION_KEY')
              if(productKey.length !== 0) {
                return _that.$t('accountPermissionManage.end') + _that.$util.format(productKey[0].expirationDate)
              }
            }
            return _that.$t('accountPermissionManage.notHave')
          },
        },
        // lang:远程控制功能
        {
          label: this.$t('accountPermissionManage.remoteControlFunction'),
          prop: 'secretKeyDistributionList',
          callback(row) {
            if(row.secretKeyDistributionList.length !== 0) {
              let productKey = row.secretKeyDistributionList.filter(item => item.type === 'TEAM_VIEWER_FUNCTION_KEY')
              if(productKey.length !== 0) {
                return _that.$t('accountPermissionManage.end') + _that.$util.format(productKey[0].expirationDate)
              }
            }
            return _that.$t('accountPermissionManage.notHave')
          },
        },
        // lang:传感器采集功能
        {
          label: this.$t('accountPermissionManage.sensorAcquisitionFunction'),
          prop: 'secretKeyDistributionList',
          callback(row) {
            if(row.secretKeyDistributionList.length !== 0) {
              let productKey = row.secretKeyDistributionList.filter(item => item.type === 'SENSOR_COLLECT_FUNCTION_KEY')
              if(productKey.length !== 0) {
                return _that.$t('accountPermissionManage.end') + _that.$util.format(productKey[0].expirationDate)
              }
            }
            return _that.$t('accountPermissionManage.notHave')
          },
        },
        // lang:内窥镜功能
        {
          label: this.$t('accountPermissionManage.endoscopicFunction'),
          prop: 'secretKeyDistributionList',
          callback(row) {
            if(row.secretKeyDistributionList.length !== 0) {
              let productKey = row.secretKeyDistributionList.filter(item => item.type === 'ENDOSCOPE_FUNCTION_KEY')
              if(productKey.length !== 0) {
                return _that.$t('accountPermissionManage.end') + _that.$util.format(productKey[0].expirationDate)
              }
            }
            return _that.$t('accountPermissionManage.notHave')
          },
        },
        // lang:联系人可见范围
        {
          label: this.$t('accountPermissionManage.contactVisibleRange'),
          prop: 'accountPermission',
          callback(row) {
            let aaa = ''
            if(row.accountPermission.visibleType === 'PART') {
              if(row.accountPermission.groupsSet !== null) {
                row.accountPermission.groupsSet.forEach(item => aaa += item.name + '、')
              }
              if(row.accountPermission.accountsSet !== null) {
                row.accountPermission.accountsSet.forEach(item => aaa += item.name + '、')
              }
            } else if(row.accountPermission.visibleType === 'ALL') {
              aaa += _that.$t('accountPermissionManage.allPermission') + '、'
            }
            return aaa.substr(0, aaa.length - 1)
          }
        },
        // lang:添加联系人
        {
          label: this.$t('accountPermissionManage.addContact'),
          prop: 'canAddOutsideUserAccount',
          callback(row) {
            return row.canAddOutsideUserAccount === 'YES' ? _that.$t('accountPermissionManage.allow') : _that.$t('accountPermissionManage.notAllow')
          }
        },
      ],
      // 表单页
      showDetail: false,
      // 导入
      showDialogImport: false,
    }
  },
  mounted() {
    // const language = this.$util.getLocalStorage('lang')
    // this.dictFindTree('ADDRESS', language)
    this.userAccountGroupFindTree()
  },
  methods: {
    userAccountGroupFindTree() {
      this.$ajax({
        url: this.$api.userAccountGroupFindTree,
        data: {
          companyId: this.$store.getters.company.id,
          scope: 'COMPANY',
        },
      }).then((data) => {
        this.config[1].tagProps.options = data
      })
    },
    // 查询字典
    dictFindTree(dictType, language) {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.dictFindTree,
        data: {
          dictType,
          language,
        },
      }).then((data) => {
        this.config[1].tagProps.options = data || []
      })
    },
    // 关闭详情页回调
    closePage(method) {
      this.showDetail = false
      if (method && method !== 'cancel') {
        this.search()
      }
    },
    // 查询按钮点击回调
    search(isActiveQuery) {
      const formData = this.$util.copyData(this.formData)
      if (formData.addressId) formData.addressId = formData.addressId.pop()
      this.$refs.tableList.searchList(formData, isActiveQuery)
    },
    // 权限按钮点击回掉
    functionMethod(item) {
      const { method } = item
      if (method !== 'add' && method !== 'import' && !this.tableRow) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }
      switch (method) {
        case 'permissionSetting':
          this.showDetail = true
          break
        default: // do something
      }
    },
  },
}
</script>
